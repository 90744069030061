<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details :title="sidebar_title" @close-sidebar="closeSidebar">
                <service-details v-if="sidebar.details.show"
                    :uuid="service_uuid"
                    :customer-uuid="customer_uuid"

                    ref="service-details"

                    @close-sidebar="closeSidebar"
                />
            </app-sidebar-details>
        </template>

        <div class="page-prepay-mobile-services">
            <app-loader v-if="loading" fixed-on="desktop" />

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1 class="heading">PrePay Mobile Services</h1>
                    </div>

                    <div class="col-12">
                        <app-error v-model="errors.show" :message="errors.message" />

                        <app-table
                            class="prepay-mobile-services-table"

                            :sort-value="filter.sort"
                            :sort-options="sort"
                            @sort-change="onSortChange"

                            :cols="cols"
                            :rows="rows"

                            :clickable="true"
                            :selected-row="service_uuid"

                            @row-click="toggleSidebar($event, 'details')"
                            @row-click-mobile="toggleSidebar($event, 'details')"
                        />

                        <app-pagination
                            v-if="pagination.total"

                            v-model="pagination.page"

                            :total="pagination.total"
                            :limit="pagination.limit"
                            :page-range="pagination.range"

                            @change="onPageChange"
                        />
                    </div>

                    <div class="col-12">
                        <app-adding-banner-service service="PrePay Mobile" :tablet-mode="true" />
                    </div>
                </div>
            </div>
        </div>
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'
import appSidebarDetails from '@/components/app-sidebar-details'

import serviceDetails from '@/views/prepay-mobile/service-details'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'
import appAddingBannerService from '@/components/app-adding-banner-service'

import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

export default {
    components: {
        baseLayout,
        appSidebarDetails,

        serviceDetails,

        appLoader,
        appError,
        appTable,
        appPagination,
        appAddingBannerService,
    },

    data() {
        return {
            filter: {
                sort: {
                    field: 'CreatedAtNanos',
                    order: 'desc', // asc, desc
                },
            },

            sort: [
                { field: 'CreatedAtNanos', title: 'Created at' },
                { field: 'UpdatedAtNanos', title: 'Updated at' },
            ],

            prepay_products: [],

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            sidebar: {
                details: {
                    show: false,
                    ref: 'service-details',
                    to: row => ({
                        name: 'prepay-mobile-service-details',
                        params: {
                            uuid: row.uuid,
                            customer_uuid: row.customer_uuid,
                        }
                    }),
                },
            },

            service_uuid: null,
            customer_uuid: null,

            is_mobile_mode: false,

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.handleWindowResize()

        window.addEventListener('resize', this.handleWindowResize)
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getPrepayProducts()
        },

        handleWindowResize() {
            this.is_mobile_mode = document.body.clientWidth <= this.$mobile_size
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getPrepayProducts()
        },

        onPageChange(page) {
            this.getPrepayProducts(page)
        },

        getPrepayProducts(page) {
            this.loading = true

            const params = {
                SPID: this.current_spid,

                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,

                'SearchOptions.SortBy':     this.filter.sort.field,
                'SearchOptions.SortDesc':   this.filter.sort.order === 'desc' ? 1 : 0,
            }

            this.$store.dispatch('api_prepay/GetPrepayProductsPaginated', params)
                .then(response => {
                    this.prepay_products = response.PrepayProducts

                    this.pagination.page  = response.PageInfo.PageNumber
                    this.pagination.total = response.PageInfo.TotalItemCount

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({
                            name: this.$route.name,
                            params: { page: this.pagination.page },
                            query: this.$route.query,
                            replace: true,
                        })
                    }

                    this.errors.show = false
                    this.errors.message = ''

                    this.loading = false
                })
                .catch(error => {
                    this.errors.show = true
                    this.errors.message = errMessage(error)

                    this.prepay_products = []

                    this.loading = false
                })
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.service_uuid = null
            this.customer_uuid = null

            this.$emit('close-sidebar')

            for (const key in this.sidebar) {
                if (this.sidebar[key].show && this.$refs[this.sidebar[key].ref] && typeof this.$refs[this.sidebar[key].ref].reset == 'function') {
                    this.$refs[this.sidebar[key].ref].reset()

                    break
                }
            }
        },

        toggleSidebar(row, type) {
            if (this.is_mobile_mode) {
                this.$router.push(this.sidebar[type].to(row))
            } else {
                for (const key in this.sidebar) {
                    this.sidebar[key].show = false
                }

                this.sidebar[type].show = true

                this.service_uuid = row.uuid
                this.customer_uuid = row.customer_uuid

                this.openSidebar()
            }
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            '$mobile_size',
        ]),

        cols() {
            return [
                { key: 'uuid',        title: 'UUID',                  highlight: true },
                { key: 'CreditCents', title: 'Credit'                                 },
                { key: 'Status',      title: 'Status', type: 'status'                 },

                { key: 'CreatedAtNanos', title: 'Created at', sort: { field: 'CreatedAtNanos' } },
                { key: 'UpdatedAtNanos', title: 'Updated at', sort: { field: 'UpdatedAtNanos' } },
            ]
        },

        rows() {
            const rows = []

            for (let i = 0, len = this.prepay_products.length; i < len; i++) {
                const prepay_product = this.prepay_products[i]

                let status = 'info'

                switch (prepay_product.Status) {
                    case 'Active': {
                        status = 'acknowledged'
                    } break

                    case 'Pending': {
                        status = 'pending'
                    } break

                    case 'Aborted': {
                        status = 'cancelled'
                    } break
                }

                rows.push({
                    uuid: prepay_product.UUID,
                    customer_uuid: prepay_product.CustomerUUID,

                    CreditCents: `$${ prepay_product.CreditCents % 100 == 0 ? prepay_product.CreditCents / 100 : (prepay_product.CreditCents / 100).toFixed(2) }`,
                    Status: {
                        status,
                        text: prepay_product.Status ? prepay_product.Status : '?',
                    },
                    CreatedAtNanos: formatDateNano(prepay_product.CreatedAtNanos),
                    UpdatedAtNanos: formatDateNano(prepay_product.UpdatedAtNanos),
                })
            }

            return rows
        },

        sidebar_title() {
            let title = ''

            switch (true) {
                case this.sidebar.details.show: {
                    title = 'Service details'
                } break
            }

            return title
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize)
    },
}
</script>

<style lang="scss">
.page-prepay-mobile-services {
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .prepay-mobile-services-table {
        @include table-cols-width((300px, 100px, 150px, 150px, 150px), true);
    }

    .app-pagination,
    .app-adding-banner-service {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-prepay-mobile-services {
        .prepay-mobile-services-table {
            @include table-cols-width((270px, 80px, 120px, 120px, 120px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-prepay-mobile-services {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .prepay-mobile-services-table {
            @include table-cols-width-mobile((90px, 184px), true);
        }

        .app-pagination,
        .app-adding-banner-service {
            margin-top: 15px;
        }
    }
}
</style>