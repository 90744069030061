<template>
    <div id="page-prepay-mobile-service-details" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12" v-if="!on_sidebar">
                    <router-link class="btn btn-back-to" :to="back_to">Back to PrePay Mobile Services</router-link>
                </div>

                <div class="col-6 col-tab-12">
                    <app-table-details
                        title="Service details"

                        :rows="rows.service_details"
                        :data="service_details"
                    />
                </div>

                <div class="col-6 col-tab-12">
                    <app-table
                        class="addons-table"

                        title="Addons"

                        :cols="rows.addons"
                        :rows="addons"

                        :mobile-mode="true"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appTableDetails from '@/components/app-table-details'
import appTable from '@/components/app-table'

import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

export default {
    props: {
        uuid:         { type: String, default: null },
        customerUuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appTableDetails,
        appTable,
    },

    data() {
        return {
            prepay_product: null,

            loading: false,

            referrer: null,
            on_sidebar: false,
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            this.getPrepayProduct()
        },

        getPrepayProduct() {
            this.loading = true

            const params = {
                SPID: this.current_spid,

                UUID: this.service_uuid,

                CustomerUUID: this.customer_uuid,
            }

            this.$store.dispatch('api_prepay/GetPrepayProductByUUID', params)
                .then(response => {
                    this.prepay_product = response

                    this.loading = false
                })
                .catch(error => {
                    this.loading = false

                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                    } else {
                        this.$router.push({
                            ...this.back_to,
                            replace: true,
                        })
                    }

                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })
                })
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        back_to() {
            return this.referrer && this.referrer.name == 'prepay-mobile-services'
                ? this.referrer
                : { name: 'prepay-mobile-services' }
        },

        service_uuid() {
            return this.uuid || this.$route.params.uuid
        },

        customer_uuid() {
            return this.customerUuid || this.$route.params.customer_uuid
        },

        rows() {
            return {
                service_details: [
                    { key: 'Name', title: 'Name' },
                    { key: 'UUID', title: 'UUID' },
                    { key: 'Status', title: 'Status', type: 'status' },
                    { key: 'CreatedAtNanos', title: 'Created at' },
                    { key: 'UpdatedAtNanos', title: 'Updated at' },
                    { key: 'RoamingFinishesAtNanos', title: 'Roaming expires at' },
                    { key: 'CreditCents', title: 'Credit' },
                    { key: 'CreditSMS', title: 'SMS' },
                    { key: 'CreditMMS', title: 'MMS' },
                    { key: 'CreditVoiceMinutes', title: 'Voice Minutes' },
                    { key: 'CreditDataMB', title: 'Data (MB)' },
                    { key: 'RolloverCreditSMS', title: 'Rollover Credit SMS' },
                    { key: 'RolloverCreditMMS', title: 'Rollover Credit MMS' },
                    { key: 'RolloverCreditVoiceMinutes', title: 'Rollover Credit Voice Minutes' },
                    { key: 'RolloverCreditDataMB', title: 'Rollover Credit Data MB' },
                ],

                addons: [
                    { key: 'UUID', title: 'UUID' },
                    { key: 'Name', title: 'Name' },
                    { key: 'Description', title: 'Description' },
                    { key: 'RetailPriceCents', title: 'Retail Price' },
                    { key: 'CreditCents', title: 'Bonus Credit' },
                    { key: 'CreditDataMB', title: 'Data' },
                    { key: 'CreditMMS', title: 'MMS' },
                    { key: 'CreditSMS', title: 'SMS' },
                    { key: 'CreditVoiceMinutes', title: 'Voice' },
                    { key: 'CreatedAtNanos', title: 'Created at' },
                    { key: 'ExpiresAtNanos', title: 'Expires at' },
                ],
            }
        },

        service_details() {
            let status = 'info'

            let service_details = {
                Name: '?',
                UUID: '?',
                Status: {
                    status,
                    text: '?',
                },
                CreatedAtNanos: '?',
                UpdatedAtNanos: '?',
                RoamingFinishesAtNanos: '?',
                CreditCents: '?',
                CreditSMS: '?',
                CreditMMS: '?',
                CreditVoiceMinutes: '?',
                CreditDataMB: '?',
                RolloverCreditSMS: '?',
                RolloverCreditMMS: '?',
                RolloverCreditVoiceMinutes: '?',
                RolloverCreditDataMB: '?',
            }

            if (this.prepay_product) {
                switch (this.prepay_product.Status) {
                    case 'Active': {
                        status = 'acknowledged'
                    } break

                    case 'Pending': {
                        status = 'pending'
                    } break

                    case 'Aborted': {
                        status = 'cancelled'
                    } break
                }

                service_details = {
                    Name: this.prepay_product.Name ? this.prepay_product.Name : '?',

                    UUID: this.prepay_product.UUID,
                    Status: {
                        status,
                        text: this.prepay_product.Status ? this.prepay_product.Status : '?',
                    },
                    CreatedAtNanos: formatDateNano(this.prepay_product.CreatedAtNanos),
                    UpdatedAtNanos: formatDateNano(this.prepay_product.UpdatedAtNanos),
                    RoamingFinishesAtNanos: this.prepay_product.RoamingFinishesAtNanos
                        ? formatDateNano(this.prepay_product.RoamingFinishesAtNanos)
                        : '-',
                    CreditCents: `$${ this.prepay_product.CreditCents % 100 == 0 ? this.prepay_product.CreditCents / 100 : (this.prepay_product.CreditCents / 100).toFixed(2) }`,
                    CreditSMS: this.prepay_product.CreditSMS,
                    CreditMMS: this.prepay_product.CreditMMS,
                    CreditVoiceMinutes: this.prepay_product.CreditVoiceMinutes,
                    CreditDataMB: this.prepay_product.CreditDataMB,
                    RolloverCreditSMS: this.prepay_product.RolloverCreditSMS,
                    RolloverCreditMMS: this.prepay_product.RolloverCreditMMS,
                    RolloverCreditVoiceMinutes: this.prepay_product.RolloverCreditVoiceMinutes,
                    RolloverCreditDataMB: this.prepay_product.RolloverCreditDataMB,
                }
            }

            return service_details
        },

        addons() {
            const addons = []

            if (this.prepay_product) {
                for (let i = 0, len = this.prepay_product.AddOns.length; i < len; i++) {
                    const addon = this.prepay_product.AddOns[i]

                    addons.push({
                        UUID: addon.UUID,
                        Name: addon.Name ? addon.Name : '?',
                        Description: addon.Description ? addon.Description : '?',
                        RetailPriceCents: `$${ addon.RetailPriceCents % 100 == 0 ? addon.RetailPriceCents / 100 : (addon.RetailPriceCents / 100).toFixed(2) }`,
                        CreditCents: `$${ addon.CreditCents % 100 == 0 ? addon.CreditCents / 100 : (addon.CreditCents / 100).toFixed(2) }`,
                        CreditDataMB: addon.CreditDataMB,
                        CreditMMS: addon.CreditMMS,
                        CreditSMS: addon.CreditSMS,
                        CreditVoiceMinutes: `${ addon.CreditVoiceMinutes } min`,
                        CreatedAtNanos: formatDateNano(addon.CreatedAtNanos),
                        ExpiresAtNanos: formatDateNano(addon.ExpiresAtNanos),
                    })
                }
            }

            return addons
        },
    },

    watch: {
        uuid() {
            if (this.service_uuid) {
                this.getPrepayProduct()
            }
        },
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.referrer = from
        })
    },
}
</script>

<style lang="scss">
#page-prepay-mobile-service-details {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 24px;
    }

    .addons-table {
        @include table-cols-width-mobile((90px, 184px), true);
    }

    &.on-sidebar {
        margin: 40px 0 32px;

        .container {
            .row {
                .col-6 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }

        .addons-table {
            margin-top: 30px;
        }
    }
}

@media (max-width: $tablet-size) {
    #page-prepay-mobile-service-details {
        .addons-table {
            margin-top: 30px;
        }
    }
}

@media (max-width: $mobile-size) {
    #page-prepay-mobile-service-details {
        margin-bottom: 64px;

        .addons-table {
            margin-top: 15px;
        }
    }
}
</style>